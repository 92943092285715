<template>
  <div>
    <a-modal
      :visible="visible"
      title="待补充物流信息"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      okText="打印"
      :width="800"
      @cancel="cancel"
      @ok="confirm"
    >
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :data-source="dataItems"
        :pagination="false"
        :scroll="{ x: 1720, y: 480 }"
      >
        <div slot="logistics_number" slot-scope="value, item">
          <span v-if="value && value.length > 0">{{ value }}</span>
          <span v-else-if="item.delivery_method === '线下配送'">{{ item.express }}</span>
          <span v-else style="color: crimson">待录入</span>
        </div>
        <div slot="action" slot-scope="value, item">
          <a-button-group size="small">
            <a-button @click="openSupplementDeliveryModal(item)">补充</a-button>
          </a-button-group>
        </div>
      </a-table>
    </a-modal>

    <SupplementDeliveryModal v-model="supplementVisible" :form="targetItem" @update="updateItem" />
  </div>
</template>

<script>
export default {
  components: {
    SupplementDeliveryModal: () => import("@/components/SupplementDeliveryModal/"),
  },
  props: ["visible", "items"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          fixed: "left",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "出库通知单号",
          dataIndex: "number",
          sorter: true,
          width: 180,
          ellipsis: true,
        },
        {
          title: "客户单号",
          dataIndex: "external_number",
          width: 180,
          ellipsis: true,
        },
        {
          title: "配送方式",
          dataIndex: "delivery_method",
          width: 180,
        },
        {
          title: "物流单号",
          dataIndex: "logistics_number",
          width: 180,
          scopedSlots: { customRender: "logistics_number" },
        },
        {
          title: "预计出库日期",
          dataIndex: "delivery_date",
          width: 120,
          ellipsis: true,
        },
        {
          title: "客户",
          dataIndex: "client_name",
          width: 120,
          ellipsis: true,
        },
        {
          title: "备注",
          dataIndex: "remark",
          width: 240,
          ellipsis: true,
        },
        {
          title: "操作",
          dataIndex: "action",
          fixed: "right",
          ellipsis: true,
          scopedSlots: { customRender: "action" },
        },
      ],
      loading: false,
      targetItem: {},
      supplementVisible: false,
      dataItems: [],
    };
  },
  methods: {
    openSupplementDeliveryModal(item) {
      this.targetItem = item;
      this.supplementVisible = true;
    },
    updateItem(item) {
      this.dataItems.splice(
        this.dataItems.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.dataItems = [...this.items];
      }
    },
  },
};
</script>

<style scoped></style>
